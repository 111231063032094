
export const environment = {
  production: false
};

export const SUBSCRIBE_URL = 'https://us-east1-cocha-qa.cloudfunctions.net/subscriptionOffer';
export const STORAGE_GOOGLE = `https://storage.googleapis.com`;

export const WAPP_FLIGHTS = 'https://www-qa.cocha.com/resultado/vuelos';
export const WAPP_PKG = 'https://www-qa.cocha.com/resultado/vuelo-hotel/h';
export const WAPP_HOTELS = 'https://www-qa.cocha.com/resultado/hoteles';

export const webMonitorUrl = 'https://static-cdn-qa.cocha.cloud/webhealth-mon';

export const ADMIN_LANDINS = 'sites-qa-int.cocha.cloud,webs-qa-int.cocha.cloud';
export const COCHA_URL = 'https://www-qa.cocha.com';

export const LANDINGS_URL = {
  landings: 'https://apis-qa.cocha.cloud/landings/get-admin',
  landing_alliances_images: `${STORAGE_GOOGLE}/gcp-production-cdn/cms/images/1_home3/alianzas`,
  landing_background: `${STORAGE_GOOGLE}/gcp-production-cdn/cms/images/home/background.jpg`,
};

export const SEO = 'https://apis-qa.cocha.cloud/landings/admin-packages';
export const SEO_FLIGHTS = 'https://apis-qa.cocha.cloud/landings/admin-flights';
export const SEO_HOTELS = 'https://apis-qa.cocha.cloud/landings/admin-hotels';
export const SEO_MULTIFLIGHTS = 'https://apis-qa.cocha.cloud/landings/admin-multiflights';
export const AGENCIES = 'https://apis-qa.cocha.cloud/configs/config-agencies';

export const COUPONS_DISCOUNT = 'https://apis-qa.cocha.cloud/api-admin-config/configs/coupons/defaultw'
export const COUPON_BENEFITS = 'https://apis-qa.cocha.cloud/api-admin-config/admin/coupon-benefits';
export const GOOGLE_PROVIDER_ID = '239664736751-h1dijuldq182bsjb2opg4o0dm1l2r9r5.apps.googleusercontent.com';
export const ALLIANCES = 'https://apis-qa.cocha.cloud/api-admin-config/configs/alianzas/alianzas-disponibles';
export const ALLIANCES_CONFIG = 'https://apis-qa.cocha.cloud/configs/alliances-campaigns-qa';
export const HOLIDAY_EXCHANGE_RATE = 'https://apis-qa.cocha.cloud/api-business-rules/rule';
export const AB_HOLIDAY = 'https://apis-qa.cocha.cloud/api-admin-config/configs/green-blue-vh-redirect/config-1';
export const SIGNIN = 'https://apis-qa-int.cocha.cloud/api-manager-external-auth/login';
export const DELETE_ACCOUNT = 'https://apis-qa-int.cocha.cloud/api-manager-external-auth/user-delete';
export const MY_ACCOUNT_CONFIG = 'https://apis-qa.cocha.cloud/api-admin-config/configs/mi-cuenta/default';